import React, { useEffect, useState } from 'react';
import { getEventsToday, getEventsByDateRange, updateReservation, addWalkIn } from '../services/api'; // Importing API calls

const EventsDashboard = () => {
    const [reservations, setReservations] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Date range state
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    // Walk-in form state
    const [walkInForm, setWalkInForm] = useState({
        name: '',
        email: '',
        children: '',
        startTime: 'current' // Default to current time option
    });

    // Function to get the current time rounded to the nearest 30 minutes
    const getRoundedTime = () => {
        const currentTime = new Date();
        const minutes = currentTime.getMinutes();
        currentTime.setMinutes(minutes < 15 ? 0 : minutes < 45 ? 30 : 60, 0, 0);
        return currentTime;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWalkInForm({
            ...walkInForm,
            [name]: value,
        });
    };

    const handleSubmitWalkIn = async (e) => {
        e.preventDefault();

        // Use selected start time or current rounded time
        const selectedStartTime = walkInForm.startTime === 'current'
            ? getRoundedTime().toISOString()
            : walkInForm.startTime;

        const walkInData = {
            name: walkInForm.name,
            email: walkInForm.email,
            children: walkInForm.children,
            scheduledevent: {
                starttime: selectedStartTime
            }
        };

        try {
            await addWalkIn(walkInData); // Send the walk-in data to the backend
            console.log('Walk-in customer added:', walkInData);
            // Optionally: Refresh reservations after adding walk-in
            const response = await getEventsToday();
            const groupedReservations = groupByStartTime(response.data.reservations);
            setReservations(groupedReservations);
        } catch (err) {
            console.error('Error adding walk-in', err);
        }

        // Clear form after submission
        setWalkInForm({ name: '', email: '', children: '', startTime: 'current' });
    };

    // Function to group reservations by scheduled event start time
    const groupByStartTime = (reservations) => {
        return reservations.reduce((groups, reservation) => {
            const startTime = reservation.scheduledevent?.starttime || 'Unknown Time'; // Handle cases where start time might be missing
            if (!groups[startTime]) {
                groups[startTime] = [];
            }
            groups[startTime].push(reservation);
            return groups;
        }, {});
    };

    // Fetch reservations based on date range or today's reservations if no date range is selected
    const fetchReservations = async () => {
        try {
            setLoading(true);
            let response;
            if (fromDate && toDate) {
                response = await getEventsByDateRange(fromDate, toDate); // Use API function for date range
            } else {
                response = await getEventsToday(); // Default to today's events
            }
            const groupedReservations = groupByStartTime(response.data.reservations);
            setReservations(groupedReservations);
        } catch (err) {
            setError('Error fetching reservations');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Fetch reservations on component mount for today's events by default
    useEffect(() => {
        fetchReservations();
    }, []); // Empty dependency array ensures this only runs on mount

    // Existing code for handling reservation status updates
    const updateReservationStatus = async (reservation, status) => {
        const updatedReservation = { ...reservation, status };
        try {
            await updateReservation(updatedReservation); // Using the API function
            console.log(`Reservation for ${reservation.email} updated to ${status}`);
            setReservations((prevReservations) => {
                const updatedReservations = { ...prevReservations };
                for (let startTime in updatedReservations) {
                    updatedReservations[startTime] = updatedReservations[startTime].map((res) =>
                        res._id === reservation._id ? { ...res, status } : res
                    );
                }
                return updatedReservations;
            });
        } catch (err) {
            console.error('Error updating reservation status', err);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <h2>Events Dashboard</h2>

            {/* Date Range Selection */}
            <div style={{ marginBottom: '20px' }}>
                <h3>Select Date Range</h3>
                <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    placeholder="From Date"
                />
                <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    placeholder="To Date"
                />
                <button onClick={fetchReservations}>Fetch Reservations</button>
            </div>

            {/* Walk-In Form */}
            <form onSubmit={handleSubmitWalkIn}>
                <h3>Add Walk-In Customer</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Customer Name"
                    value={walkInForm.name}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Customer Email"
                    value={walkInForm.email}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="number"
                    name="children"
                    placeholder="Number of Children"
                    value={walkInForm.children}
                    onChange={handleInputChange}
                    required
                />

                {/* Dropdown for selecting start time */}
                <select
                    name="startTime"
                    value={walkInForm.startTime}
                    onChange={handleInputChange}
                >
                    <option value="current">Current Time (Rounded)</option>
                    {Object.keys(reservations).map((startTime) => (
                        <option key={startTime} value={startTime}>
                            {new Date(startTime).toLocaleString()} {/* Display date and time */}
                        </option>
                    ))}
                </select>

                <button type="submit">Add Walk-In</button>
            </form>

            {/* Display reservations grouped by event start time */}
            {Object.keys(reservations).map((startTime) => (
                <div key={startTime}>
                    <h3>
                        Event starting at: {startTime !== 'Unknown Time' ? new Date(startTime).toLocaleString() : 'Unknown Time'}
                    </h3> {/* Display both date and time */}
                    <table border="1" cellPadding="10">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Children</th>
                                <th>Children's Names</th>
                                <th>Children's Birthdays</th>
                                <th>Waiver Status</th>
                                <th>Member Status</th>
                                <th>Payment Details</th>
                                <th>Arrived</th>
                                <th>No Show</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservations[startTime].map((reservation) => {
                                const childrenNames = reservation.waivers
                                ? reservation.waivers
                                    .map((waiver) => waiver.child)
                                    .filter((child) => child) // Ensure no undefined or null values
                                    .join(', ')
                                : 'N/A';

                                const childrenBirthdays = reservation.waivers
                                ? reservation.waivers
                                    .map((waiver) => waiver.birthday)
                                    .filter((birthday) => birthday) // Ensure no undefined or null values
                                    .join(', ')
                                : 'N/A';

                                return (
                                    <tr key={reservation._id}>
                                        <td>{reservation.name || 'N/A'}</td>
                                        <td>{reservation.email || 'N/A'}</td>
                                        <td>
                                            {reservation.questionsandanswers?.find((qa) =>
                                                qa.question.includes('children')
                                            )?.answer || reservation.children || 'N/A'}
                                        </td>
                                        <td>{childrenNames}</td> {/* Display children's names */}
                                        <td>{childrenBirthdays}</td> {/* Display children's birthdays */}
                                        <td>{reservation.waivers ? 'Waiver Received' : 'No Waiver'}</td>
                                        <td>{reservation.isMember ? 'Member' : 'Non-Member'}</td>
                                        <td>
                                            {/* Loop through latestPayment array and display all payments */}
                                            {reservation.latestPayment?.length ? (
                                                reservation.latestPayment.map((payment, index) => (
                                                    <div key={index}>
                                                        <p>Amount: ${((payment.amount || 0) / 100).toFixed(2)}</p>
                                                        <p>Date: {payment.created_at ? new Date(payment.created_at).toLocaleDateString() : 'N/A'}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                'No Payments'
                                            )}
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={reservation.status === 'arrived'}
                                                onChange={() => updateReservationStatus(reservation, 'arrived')}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={reservation.status === 'no show'}
                                                onChange={() => updateReservationStatus(reservation, 'no show')}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default EventsDashboard;
